$theme-colors: (
        "primary": #008B4B
);

.bg-light-primary {
  background-color: #b8dfcd;
}

.post-image {
  background-color: black;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 350px
}

.input-group.md-form.form-sm.form-1 input {
  border: 1px solid #bdbdbd;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.List {
  border: 1px solid #d9dddd;
}

.ListItemEven,
.ListItemOdd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListItemEven {
  background-color: #f8f8f0;
}

.outer-circle {
  width: 56px;
  height: 56px;
  padding:2px;
  border-radius: 50%;
  border: 2px solid #008B4B;
  background-clip: content-box;
  margin:0 auto;
}

// Import Bootstrap and its default variables
@import 'node_modules/bootstrap/scss/bootstrap';